import { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedUser: null,
      logList: [],
      logPages: 1,
      logCount: 0,
      showFilterModal: false,
      showView: false,
      selectedLog: null,
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getLog = (querystring = "") => {
      Get(
        `/audits?limit=10&source=user` + querystring,
        this.getLogSuccess,
        this.getLogError,
        this.load
      );
    };
    getLogSuccess = (payload) => {
      this.setState({
        logList: payload.data,
        logCount: payload.count,
      });
    };
    getLogError = (error) => requestError(error);

    getUser = (id = "") => {
      Get(
        `/single-user?id=${id}`,
        this.getUserSuccess,
        this.getUserError,
        this.load
      );
    };

    getUserSuccess = (payload) => {
      this.setState({
        selectedUser: payload?.data[0],
      });
    };

    getUserError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoad={this.state.loading}
          getLog={this.getLog}
          getUser={this.getUser}
          selectedUser={this.state.selectedUser}
          onChangeHOC={this.onChangeHOC}
          showFilterModal={this.state.showFilterModal}
          showView={this.state.showView}
          selectedLog={this.state.selectedLog}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
