import ConfirmationModal from "components/Modal/confirmation";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Alert,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import { validateEmail } from "utils/helper";
import "../index.scss";

function CreateNewUserForm({ onSubmit, getRole, searchUser }) {
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumberPrefix, setContactNumberPrefix] = useState("+60");
  const [contactNumber, setContactNumber] = useState("");
  const [sstNumber, setSstNumber] = useState("");
  const [excludeSstCharges, setExcludeSstCharges] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isHouseAdvertiser, setIsHouseAdvertiser] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingPostcode, setBillingPostcode] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [description, setDescription] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertError, setAlertError] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onDismissAlert = () => {
    setAlertVisible(false);
    setAlertError("");
  };

  const validateForm = () => {
    // Reset all error states

    setAlertVisible(false);
    setAlertError("");

    let isValid = true;
    const contactNumberRegex = /^[0-9]{7,12}$/;
    const postcodeRegex = /^[0-9]+$/;
    const cityRegex = /^[A-Za-z\s]+$/;

    if (!roleId) {
      // Validate role_id
      setAlertError("User Role is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (!name) {
      // Validate name
      setAlertError("Name is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (!email) {
      // Validate email
      setAlertError("Email is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (!validateEmail(email)) {
      setAlertError("Email is not valid.");
      setAlertVisible(true);
      isValid = false;
    } else if (!password) {
      // Validate password
      setAlertError("Password is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (password.length < 8) {
      setAlertError("Password must be at least 8 characters long.");
      setAlertVisible(true);
      isValid = false;
    } else if (!confirmPassword) {
      setAlertError("Confirm Password is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (confirmPassword !== password) {
      setAlertError("Confirm Password does not match Password.");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !contactNumber) {
      setAlertError("Contact Number is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (contactNumber && !contactNumberRegex.test(contactNumber)) {
      setAlertError("Please key in correct format of contact number.");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !registrationNumber) {
      setAlertError("Registration Number is required.");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !address) {
      setAlertError("Address is required.");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !postcode) {
      setAlertError("Postcode is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (postcode && !postcodeRegex.test(postcode)) {
      setAlertError("Please key in valid Postcode.");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !city) {
      setAlertError("City is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (city && !cityRegex.test(city)) {
      setAlertError("Please key in valid City (text only).");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !billingAddress) {
      setAlertError("Billing Address is required.");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !billingPostcode) {
      setAlertError("Billing Postcode is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (billingPostcode && !postcodeRegex.test(billingPostcode)) {
      setAlertError("Please key in valid Billing Postcode.");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !billingCity) {
      setAlertError("Billing City is required.");
      setAlertVisible(true);
      isValid = false;
    } else if (billingCity && !cityRegex.test(billingCity)) {
      setAlertError("Please key in valid Billing City (text only).");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !description) {
      setAlertError("Description is required.");
      setAlertVisible(true);
      isValid = false;
    } else if ((roleId === 8 || roleId === 9) && !websiteUrl) {
      setAlertError("Website URL is required.");
      setAlertVisible(true);
      isValid = false;
    }

    // Add more validation rules for other fields as needed
    setShowConfirmModal(false);
    return isValid;
  };

  const handleSubmit = () => {
    // Check form validation
    if (validateForm()) {
      // Call the onSubmit callback if the form is valid
      onSubmit(
        {
          email: email,
          name: name,
          password: password,
          confirm_password: confirmPassword,
          platform: "myWheels",
          role_id: roleId,
          mobile_number: !contactNumber
            ? null
            : contactNumberPrefix + contactNumber,
          is_house_advertiser: isHouseAdvertiser,
          sst_registration_number: sstNumber,
          is_exclude_sst: excludeSstCharges,
          registration_number: registrationNumber,
          advertiser_address: address,
          postcode: postcode,
          city: city,
          billing_address: billingAddress,
          billing_postcode: billingPostcode,
          billing_city: billingCity,
          description: description,
          website_url: websiteUrl,
        },
        roleId === 8 || roleId === 9
      );
    }
  };

  const asyncGetRole = async () => {
    try {
      setLoading(true);
      const results = await getRole();

      //INFO: Tempoarary hide un-supported roles
      //INFO: Show only ‘10.Customer’ ‘11.Normal’ ‘14.Super Admin’ ‘2.Editor’
      const showId = [10, 11, 14, 2];
      const finalRoles = [];

      if (results.length > 0)
        showId.map((id) => {
          const found = results.find((item) => item.id === id);
          if (found) {
            finalRoles.push(found);
          }
        });

      setRoleOptions(finalRoles);
    } catch (error) {
      // Handle error appropriately
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    asyncGetRole();
  }, []);

  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          <h6 style={{ fontWeight: "bold" }}>Basic Information</h6>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>User Role</Label>
            <Select
              name="userRoleSelect"
              placeholder="Select Role"
              isClearable
              isSearchable
              onChange={(newValue) => {
                if (!!newValue) {
                  setRoleId(newValue.value);
                } else {
                  setRoleId("");
                }
              }}
              options={roleOptions.map((role) => ({
                label: role.display_name,
                value: role.id,
              }))}
              isLoading={loading}
            />
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>Name</Label>
            <Input
              type={"text"}
              placeholder={"Enter User Name"}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>Email Address</Label>
            <Input
              type={"text"}
              placeholder={"Enter User Email Address"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>Contact Number</Label>
            <InputGroup>
              <UncontrolledDropdown>
                <DropdownToggle
                  color="#ffffff"
                  caret
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem 0 0 0.25rem",
                  }}
                >
                  {contactNumberPrefix}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setContactNumberPrefix("+60")}>
                    +60
                  </DropdownItem>
                  <DropdownItem onClick={() => setContactNumberPrefix("+61")}>
                    +61
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Input
                type={"text"}
                placeholder={"Enter User Contact Number"}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                }}
              />
            </InputGroup>
          </FormGroup>

          {(roleId === 8 || roleId === 9) && (
            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>
                SST Registration Number
              </Label>
              <Input
                type={"text"}
                placeholder={"Enter SST Registration Number"}
                onChange={(e) => {
                  setSstNumber(e.target.value);
                }}
              />
              <FormGroup check className="check-option">
                <Input
                  type="checkbox"
                  checked={excludeSstCharges}
                  onChange={(e) => setExcludeSstCharges(e.target.checked)}
                />
                <Label check className="role-label">
                  Exclude SST Charges
                </Label>
              </FormGroup>
            </FormGroup>
          )}

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>Password</Label>
            <Input
              type={"password"}
              placeholder={"Enter User Password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label style={{ fontWeight: "bold" }}>Confirm Password</Label>
            <Input
              type={"password"}
              placeholder={"Re-enter User Password"}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />

            {(roleId === 8 || roleId === 9) && (
              <FormGroup check className="check-option">
                <Input
                  type="checkbox"
                  checked={isHouseAdvertiser}
                  onChange={(e) => setIsHouseAdvertiser(e.target.checked)}
                />
                <Label check className="role-label">
                  Are you a House Advertiser?
                </Label>
              </FormGroup>
            )}
          </FormGroup>

          {(roleId === 8 || roleId === 9) && (
            <>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>
                  Registration Number
                </Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Registration Number"}
                  onChange={(e) => {
                    setRegistrationNumber(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Address</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Address"}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Postcode</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Postcode"}
                  onChange={(e) => {
                    setPostcode(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>City</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter City"}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Billing Address</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Billing Address"}
                  onChange={(e) => {
                    setBillingAddress(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Billing Postcode</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Billing Postcode"}
                  onChange={(e) => {
                    setBillingPostcode(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Billing City</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Billing City"}
                  onChange={(e) => {
                    setBillingCity(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Description</Label>
                <Input
                  type={"textarea"}
                  placeholder={"Enter Description"}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ fontWeight: "bold" }}>Website URL</Label>
                <Input
                  type={"text"}
                  placeholder={"Enter Website URL"}
                  onChange={(e) => {
                    setWebsiteUrl(e.target.value);
                  }}
                />
              </FormGroup>
            </>
          )}
        </CardBody>
      </Card>
      <div className="container-fluid mb-3">
        <div className="row justify-content-end">
          <div className="col-auto" style={{ padding: "6px" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowConfirmModal(true)}
            >
              <i className="pe-lg pe-7s-diskette"></i> Save User
            </button>
          </div>
        </div>
      </div>

      <ConfirmationModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onClickConfirm={() => {
          handleSubmit();
        }}
        title="Are you sure you want to save?"
        message="Please confirm this action."
        confirmContent="Yes, Save"
        confirmColor="primary"
      />
      <Alert color="danger" isOpen={alertVisible} toggle={onDismissAlert}>
        {alertError}
      </Alert>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.ProfileReducer.profile,
});

export default connect(mapStateToProps)(CreateNewUserForm);
