import ReactTable from "react-table";
import _ from "lodash";
import { Button, UncontrolledTooltip, Row, Col } from "reactstrap";
import {
	DropdownToggle,
	DropdownMenu,
	UncontrolledButtonDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

const statusButtons = {
	Active: "btn btn-outline-success disabled",
	Expired: "btn btn-outline-info disabled",
	Sent: "btn btn-outline-success disabled",
	Draft: "btn btn-outline-primary disabled",
	Scheduled: "btn btn-outline-warning disabled",
	Failed: "btn btn-outline-danger disabled",
	Disabled: "btn btn-outline-secondary disabled",
	"Active - Both": "btn btn-outline-success disabled",
	"Active - Candidate": "btn btn-outline-success disabled",
	Inactive: "btn btn-outline-secondary disabled",
	draft: "btn btn-outline-primary disabled",
	publish: "btn btn-outline-success disabled",
	suspend: "btn btn-outline-secondary disabled",
	disable: "btn btn-outline-secondary disabled",
};

const CustomChinaPressTable = ({
	data,
	hidePagination = false,
	accessorKey,
	actionsContent,
	columnsContent,
	defaultPageSize,
	showPageSizeOptions,
	menuContent,
	...props
}) => {
	return (
		<ReactTable
			resizable={false}
			defaultPageSize={defaultPageSize || 10}
			showPageSizeOptions={showPageSizeOptions || false}
			data={data || []}
			columns={[
				..._.map(columnsContent, (content) => {
					return {
						Header: content.Header,
						accessor: content?.accessorKey ?? "",
						sortable: content.accessorKey ? true : false,
						id: accessorKey,
						maxWidth: content.maxWidth ? content.maxWidth : undefined,
						width: content.width ? content.width : undefined,
						headerStyle: {
							textAlign: !content.centerColumn ? "start" : "center",
						},
						className: content.centerColumn
							? "d-flex justify-content-center text-center"
							: "",
						Cell: (row) => {
							const cellContent = content.Cell(row.original).props.children;
							let cellClassName = content.isStatusButton
								? `${statusButtons[cellContent]} justify-content-center text-center`
								: "";

							return (
								<div className={cellClassName} style={{ overflow: "auto" }}>
									{cellContent}
								</div>
							);
						},
					};
				}),
				...(actionsContent?.[0]
					? [
							{
								sortable: false,
								maxWidth: actionsContent.length * 100,
								Cell: (row) => (
									<div
										className="d-flex justify-content-center flex-wrap"
										style={{ flex: 1, gap: 5 }}>
										{actionsContent.map((action, actionIndex) => (
											<>
												<Button
													id={`${action.actionID}-${row.original.id}`}
													color={action.color || action.colorFunc(row.original)}
													disabled={
														action.disabled
															? action.disabled(row.original, actionIndex)
															: false
													}
													className={`btn-icon btn-icon-only`}
													onClick={() =>
														action.onClick(row.original, actionIndex)
													}>
													{typeof action.content === "object"
														? action.content
														: action.content(row.original)}
												</Button>
												<UncontrolledTooltip
													target={`${action.actionID}-${row.original.id}`}
													placement={action.tooltipPosition || "top"}>
													{action.tooltipContent}
												</UncontrolledTooltip>
											</>
										))}
									</div>
								),
							},
					  ]
					: []),
				...(menuContent?.[0]
					? [
							{
								Header: "Action",
								sortable: false,
								maxWidth: menuContent.length * 100,
								Cell: (row) => (
									<>
										<UncontrolledButtonDropdown>
											<DropdownToggle color="link">
												<div
													id="ViewMore"
													className="ion-md-more"
													style={{
														fontSize: "1.2rem",
														padding: 8,
														overflow: "initial",
													}}></div>
											</DropdownToggle>
											<DropdownMenu
												right
												className="dropdown-menu-sm"
												style={{ width: "7px", margin: 0, padding: 0 }}>
												{menuContent.map((menu, menuIndex) => (
													<div
														key={menuIndex}
														className="dropdown-menu-header-inner"
														style={{ padding: "0.3px 0.1px", margin: 0 }}>
														{menu.type === "link" ? (
															<Link
																style={{
																	width: "100%",
																	display: "block",
																	textAlign: "center",
																}}
																className="dropdown-item"
																to={`/banner-inhouse/preview/${row.original.id}`}
																target="_blank"
																rel="noopener noreferrer">
																{menu.menuTooltip}
															</Link>
														) : (menu.menuID === "InactiveAdvertiser" && row.original.status === 'inactive') 
																||  (menu.menuID === "ActivateAdvertiser" && row.original.status === 'active') ?
															(<></>) : (
															<div
																style={{
																	width: "100%",
																	display: "block",
																	textAlign: "center",
																}}
																className="dropdown-item"
																onClick={() =>
																	menu.onClick(row.original, menuIndex)
																}>
																{menu.menuTooltip}
															</div>
														)
														}
													</div>
												))}
											</DropdownMenu>
										</UncontrolledButtonDropdown>
									</>
								),
							},
					  ]
					: []),
			]}
			showPagination={!hidePagination}
			{...props}
		/>
	);
};

export default CustomChinaPressTable;
