import { EnumPermission } from "constants/Enums";

export const MainNav = [
  {
    moduleID: EnumPermission.USER,
    icon: "pe-7s-note",
    label: "⚠️ Manage User",
    to: "#/manage-user",
  },
  {
    moduleID: EnumPermission.AUDIT,
    icon: "pe-7s-note2",
    label: "Audit Log",
    content: [
      {
        icon: "pe-7s-note2",
        label: "Users Table",
        to: "#/audit-log/user",
      },
      // {
      // 	icon: "pe-7s-note2",
      // 	label: "Orders Table",
      // 	to: "#/audit-log/orders",
      // },
      // {
      //   icon: "pe-7s-note2",

      //   label: "Customer Data Table",
      //   to: "#/audit-log/customer",
      // },
      // {
      // 	icon: "pe-7s-note2",
      // 	label: "Payment Methods Table",
      // 	to: "#/audit-log/payment-method",
      // },
      {
        icon: "pe-7s-note2",
        label: "Admin Actions Table",
        to: "#/audit-log/admin-actions",
      },
    ],
  },
];
