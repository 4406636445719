import React from "react";
import { Card, Row, Col, CardBody } from "reactstrap";

function View({ selectedLog }) {
	return (
		<>
			<p>Log Entries</p>
			<Card style={{ border: "1px solid #D9D9D9" }}>
				<Row style={{ margin: 0 }}>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>Date/Time</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>28-08-2923 10:15AM</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>User</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>john.doe@example.com</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>Action</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>Login</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>Payment Method ID</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>john.doe@example.com</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>IP Address</span>
					</Col>
					<Col
						md={6}
						style={{ padding: 16, borderBottom: "1px solid #D9D9D9" }}>
						<span>2402:1980:8250:3b38:3cb8:daff:fe81:ef19</span>
					</Col>
				</Row>
			</Card>
		</>
	);
}

export default View;
