import _ from "lodash";
import { Component } from "react";
import MetisMenu from "react-metismenu";
import { connect } from "react-redux";

import { setEnableMobileMenu } from "reducers/ThemeOptions";

import { RolesPermission } from "Config";
import { MainNav } from "./dataMainNav";

class VerticalNavWrapper extends Component {
  state = {
    readMenu: [],
  };

  componentDidMount = () => {
    this.onProcessSideMenu(this.props.store.ProfileReducer.profile.roles);
  };

  componentDidUpdate = (pp) => {
    if (pp.store.ProfileReducer !== this.props.store.ProfileReducer) {
      this.onProcessSideMenu(this.props.store.ProfileReducer.profile.roles);
    }
  };

  onProcessSideMenu = (roles) => {
    let tempModule = _.find(RolesPermission, { id: roles[0].id });

    if (tempModule) {
      let tempFiltered = MainNav.filter((nav) => {
        return tempModule.permissions.includes(+nav.moduleID);
      });

      this.setState({
        readMenu: tempFiltered,
      });
    }
  };

  render = () => {
    return (
      <>
        <br />
        <MetisMenu
          content={this.state.readMenu}
          onSelected={() =>
            this.props.setEnableMobileMenu(!this.props.enableMobileMenu)
          }
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  data: MainNav,
  store: state,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

export default connect(mapStateToProps, {
  setEnableMobileMenu,
})(VerticalNavWrapper);
