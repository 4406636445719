import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import Login from "./containers/Login";
import ManageUser from "./containers/ManageUser";
import CreateUser from "./containers/ManageUser/CreateUser";

import AuditUser from "containers/AuditLog/Users";
import AuditOrder from "containers/AuditLog/Orders";
import AuditCustomer from "containers/AuditLog/CustomerData";
import AuditPayment from "containers/AuditLog/PaymentMethods";
import AdminActions from "containers/AuditLog/AdminActions";

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			!true ? <Redirect to="/login" /> : <Component {...props} />
		}
	/>
);

function CustomRouter({ location, ...props }) {
	return (
		<Switch>
			<Route exact path="/login" component={Login} initialPath />
			<PrivateRoute path="/manage-user/create" component={CreateUser} />
			<PrivateRoute path="/manage-user/edit/:id" component={CreateUser} />
			<PrivateRoute path="/manage-user" component={ManageUser} />
			<PrivateRoute path="/audit-log/user" component={AuditUser} />
			<PrivateRoute path="/audit-log/orders" component={AuditOrder} />
			<PrivateRoute path="/audit-log/customer" component={AuditCustomer} />
			<PrivateRoute path="/audit-log/payment-method" component={AuditPayment} />
			<PrivateRoute path="/audit-log/admin-actions" component={AdminActions} />

			<Redirect from={"/"} to={"/login"} />
		</Switch>
	);
}

const mapStateToProps = (state) => ({ data: state });
export default compose(connect(mapStateToProps), withRouter)(CustomRouter);
