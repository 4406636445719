import { CheckUserRolesPermissons } from "actions/roles";
import TemplateHeader from "components/Header";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateSidemenu from "components/Menu/Sidemenu";
import CustomModal from "components/Modal";
import CustomModalBody from "components/Modal/body";
import Pagination from "components/Pagination";
import CustomTable from "components/Table";
import TemplateContainerMain from "components/Template";
import PageTitle from "components/Title/PageTitle";
import { RolesPermission } from "Config";
import { EnumPermission } from "constants/Enums";
import _ from "lodash";
import moment from "moment";
import { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { compose } from "redux";
import LogAPI from "./action.js";
import FilterForm from "./Form";
import View from "./View";

class UserLog extends Component {
  state = {
    filterQuery: "",
  };

  componentDidMount = () => {
    let tempModule = _.find(RolesPermission, {
      id: CheckUserRolesPermissons(this.props.data.ProfileReducer.profile.roles)
        ?.id,
    });

    if (
      (tempModule && !tempModule.permissions.includes(EnumPermission.AUDIT)) ||
      !tempModule
    ) {
      return this.props.history.push("/");
    }
    this.props.getLog();
  };

  componentDidUpdate = (pp) => {
    const { logPages, getLog } = this.props;
    const { filterQuery } = this.state;
    if (pp.logPages !== logPages) {
      getLog(`${!!filterQuery ? filterQuery : ""}`);
    }
  };

  renderLogTable = () => {
    const { logList, onLoad, onChangeHOC, getUser } = this.props;
    return (
      <CustomTable
        data={logList}
        showPagination={false}
        loading={onLoad}
        columnsContent={[
          {
            Header: "Log ID",
            accessorKey: "log_id",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => {
              return <>{val.id}</>;
            },
          },
          {
            Header: "User ID",
            accessorKey: "user_id",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => {
              return <>{val.user_id}</>;
            },
          },
          {
            Header: "Actions",
            accessorKey: "actions",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => {
              return <>{val.actions}</>;
            },
          },
          {
            Header: "Timestamp",
            accessorKey: "timestamp",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => {
              return (
                <>{moment(val.created_at).format("DD/MM/YYYY \n hh:mm:ssA")}</>
              );
            },
          },
          {
            Header: "IP Address",
            accessorKey: "ip_address",
            centerColumn: true,
            isStatusButton: false,
            Cell: (val) => {
              return <>{val.ip_address}</>;
            },
          },
          {
            Header: "",
            accessorKey: "id",
            centerColumn: true,
            isStatusButton: true,
            Cell: (val) => (
              <>
                <Button
                  type="link"
                  color="none"
                  onClick={() => {
                    onChangeHOC("showView", true);
                    onChangeHOC("selectedLog", val);
                    getUser(val.user_id);
                  }}
                >
                  <i className="pe-lg pe-7s-look blue-filter" />
                </Button>
              </>
            ),
          },
        ]}
      />
    );
  };

  renderFilterModal = () => {
    const { getLog, onChangeHOC, showFilterModal } = this.props;

    const customSetState = (newState) => {
      this.setState(newState);
    };

    return (
      <CustomModal
        size={"lg"}
        title="Audit Log Filter"
        isOpen={showFilterModal}
        onClose={() => onChangeHOC("showFilterModal", false)}
      >
        <FilterForm
          onSubmit={getLog}
          onChangeHOC={onChangeHOC}
          handleState={customSetState}
        />
      </CustomModal>
    );
  };

  render = () => {
    const { onChangeHOC, logPages, logCount } = this.props;

    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={this.props.data.ProfileReducer.profile}
          />
          <div className="app-main">
            <TemplateSidemenu
              userPermissions={this.props.userPermissions}
              user={this.props.user}
              userRole={this.props.userRole}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <>
                  <PageTitle
                    heading="Audit Log - User Table"
                    buttons={[
                      {
                        color: "",
                        className: "btn-outline-primary",
                        onClick: () => onChangeHOC("showFilterModal", true),
                        content: (
                          <>
                            <span>Filter</span>
                          </>
                        ),
                      },
                    ]}
                  />
                  <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                  >
                    <Row>
                      <Col md={12}>
                        <Card className="main-card mb-3">
                          <CardBody>
                            {this.renderLogTable()}
                            <Pagination
                              pageIndex={logPages}
                              totalCount={logCount}
                              onChangePage={(val) =>
                                onChangeHOC("logPages", val)
                              }
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </ReactCSSTransitionGroup>
                  {this.renderFilterModal()}
                  {this.props.onLoad && <LoadingOverlay />}
                </>
              </div>
            </div>
          </div>
        </TemplateContainerMain>
        <CustomModal
          size={"lg"}
          isOpen={this.props.showView}
          onClose={() => this.props.onChangeHOC("showView", false)}
        >
          <CustomModalBody>
            <View
              selectedLog={this.props.selectedLog}
              selectedUser={this.props.selectedUser}
            />
          </CustomModalBody>
        </CustomModal>
      </>
    );
  };
}

export default compose(LogAPI)(UserLog);
