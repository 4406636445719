import { Component } from "react";
import { connect } from "react-redux";

import { Get, Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      userList: [],
      userListPages: 1,
      userListCount: 0,
      showFilterModal: false,
      showViewModal: false,
      showEditModal: false,
      showInactiveModal: false,
      selectedUsername: "",
      selectedRole: "All",
      roleList: [],
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getUserList = (querystring = "") => {
      Get(
        "/users?limit=10&platform=myWheels" + querystring,
        this.getUserListSuccess,
        this.getUserListError,
        this.load
      );
    };
    getUserListSuccess = (payload) => {
      this.setState({
        userList: payload.data,
        userListCount: payload.count,
      });
    };
    getUserListError = (error) => requestError(error);

    updateUser = (dataToSubmit) => {
      Put(
        `/users/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateUserSuccess,
        this.updateUserError,
        this.load
      );
    };
    updateUserSuccess = () => {
      this.getUserList();
      this.setState({
        // hide modals after update
        showInactiveModal: false,
      });
      requestSuccess("User updated successfully.");
    };
    updateUserError = (error) => requestError(error);

    getRole = () =>
      Get(
        `/roles?platform=myWheels`,
        this.getRoleSuccess,
        this.getRoleError,
        this.load
      );
    getRoleSuccess = (payload) => {
      const finalRoles = [];
      //INFO: Show only ‘10.Customer’ ‘11.Normal’ ‘14.Super Admin’ ‘2.Editor’
      const showId = [10, 11, 14, 2];
      if (payload.data.length > 0) {
        showId.map((id) => {
          const found = payload.data.find((item) => item.id === id);
          if (found) {
            finalRoles.push(found);
          }
        });
      }
      this.setState({
        roleList: finalRoles,
      });
    };
    getRoleError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          userList={this.state.userList}
          userListPages={this.state.userListPages}
          userListCount={this.state.userListCount}
          onLoad={this.state.loading}
          getUserList={this.getUserList}
          updateUser={this.updateUser}
          getRole={this.getRole}
          roleList={this.state.roleList}
          onChangeHOC={this.onChangeHOC}
          showFilterModal={this.state.showFilterModal}
          showViewModal={this.state.showViewModal}
          showEditModal={this.state.showEditModal}
          showInactiveModal={this.state.showInactiveModal}
          selectedUsername={this.state.selectedUsername}
          selectedRole={this.state.selectedRole}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
