export const EnumPermission = Object.freeze({
    NONE: 0,
    USER: 1,
    AUDIT: 2,
})

// Role id in DB, TODO: set based on key in DB instead
export const EnumRole = Object.freeze({
    NONE: 0,
    ADMIN: 14,
    CUSTOMER: 10,
    EDITOR: 2,
})